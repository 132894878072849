'use strict'

###*
 # @ngdoc object
 # @name mundoSettings
 # @description

###
angular
  .module 'mundoSettings', []
